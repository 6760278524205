import GlobalContentDe from '@/assets/data/de/global-content.json'
import GlobalContentEn from '@/assets/data/en/global-content.json'
import GlobalContentEs from '@/assets/data/es/global-content.json'
import GlobalContentFr from '@/assets/data/fr/global-content.json'
import GlobalContentIt from '@/assets/data/it/global-content.json'
import { SHOW_SPARES, SHOW_ULTIMATE, SHOW_ULTIMATE_SPARES, SHOW_PAST_ORDERS, ORDER_A_STAIRLIFT, VIEW_FAQS_NEWS_DOWNLOADS, CLM_REGISTER_STAIRLIFT, CLM_STAIRLIFT_SEARCH, CLM_VIEW_STAIRLIFT_DETAILS, CLM_DETAILED_HISTORY_VIEW, CLM_VIEW_REGISTERED_STAIRLIFT_LIST } from '@/permissions'
import { useAuthStore } from '@/store/auth'
import { useMainStore } from '@/store/main'
import { createRouter, createWebHistory } from 'vue-router'

const globalContentDe = GlobalContentDe as GlobalContent
const globalContentEn = GlobalContentEn as GlobalContent
const globalContentEs = GlobalContentEs as GlobalContent
const globalContentFr = GlobalContentFr as GlobalContent
const globalContentIt = GlobalContentIt as GlobalContent

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      middleware: 'auth',
      title: 'index'
    }
  },
  {
    path: '/ergo-straight',
    name: 'ergoStraight',
    component: () => import('@/views/ErgoStraightView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ergo_straight',
      permissions: [ORDER_A_STAIRLIFT],
      netsuiteIdRequired: true,
      impersonatingBypass: ['platinum_admin', 'platinum_office', 'platinum_super_admin']
    }
  },
  {
    path: '/ergo-curve',
    name: 'ergoCurve',
    component: () => import('@/views/ErgoCurveView.vue'),
    meta: {
      middleware: 'auth',
      title: 'curve',
      permissions: [ORDER_A_STAIRLIFT],
      netsuiteIdRequired: true,
      impersonatingBypass: ['platinum_admin', 'platinum_office', 'platinum_super_admin']
    }
  },
  {
    path: '/spares',
    name: 'spares',
    component: () => import('@/views/SparesView.vue'),
    meta: {
      middleware: 'auth',
      title: 'spares',
      permissions: [SHOW_SPARES, SHOW_ULTIMATE_SPARES],
      netsuiteIdRequired: true,
      availableOffline: false
    }
  },
  {
    path: '/spares/checkout',
    name: 'sparesCheckout',
    component: () => import('@/views/SparesCheckout.vue'),
    meta: {
      middleware: 'auth',
      title: 'spares',
      permissions: [SHOW_SPARES, SHOW_ULTIMATE_SPARES],
      netsuiteIdRequired: true,
      availableOffline: false
    }
  },
  {
    path: '/spares/basket',
    name: 'sparesBasket',
    component: () => import('@/views/SparesBasket.vue'),
    meta: {
      middleware: 'auth',
      title: 'spares_basket',
      permissions: [SHOW_SPARES, SHOW_ULTIMATE_SPARES],
      netsuiteIdRequired: true,
      availableOffline: false
    }
  },
  {
    path: '/ultimate',
    name: 'ultimate',
    component: () => import('@/views/UltimateView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ultimate',
      permissions: [SHOW_ULTIMATE, ORDER_A_STAIRLIFT],
      netsuiteIdRequired: true
    }
  },
  {
    path: '/order/:uuid/success',
    name: 'orderSuccess',
    component: () => import('@/views/OrderSuccessView.vue'),
    meta: {
      middleware: 'auth',
      permissions: [ORDER_A_STAIRLIFT, SHOW_SPARES, SHOW_ULTIMATE_SPARES],
      netsuiteIdRequired: true,
      title: 'success'
    }
  },
  {
    path: '/faqs/:categoryId?',
    name: 'faqs',
    component: () => import('@/views/FaqsView.vue'),
    meta: {
      middleware: 'auth',
      title: 'faqs',
      permissions: [VIEW_FAQS_NEWS_DOWNLOADS],
      availableOffline: false
    }
  },
  {
    path: '/saved-orders',
    name: 'savedOrders',
    component: () => import('@/views/SavedOrdersView.vue'),
    meta: {
      middleware: 'auth',
      permissions: [ORDER_A_STAIRLIFT, SHOW_SPARES, SHOW_ULTIMATE_SPARES],
      netsuiteIdRequired: true,
      title: 'saved_orders',
      impersonatingBypass: ['platinum_admin', 'platinum_office', 'platinum_super_admin']
    }
  },
  {
    path: '/downloads/:categoryId?',
    name: 'downloads',
    component: () => import('@/views/DownloadsView.vue'),
    meta: {
      middleware: 'auth',
      title: 'downloads',
      permissions: [VIEW_FAQS_NEWS_DOWNLOADS],
      availableOffline: false
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/NewsView.vue'),
    meta: {
      middleware: 'auth',
      title: 'news',
      permissions: [VIEW_FAQS_NEWS_DOWNLOADS],
      availableOffline: false
    }
  },
  {
    path: '/news/:id',
    name: 'article',
    component: () => import('@/views/ArticleView.vue'),
    meta: {
      middleware: 'auth',
      title: 'news',
      permissions: [VIEW_FAQS_NEWS_DOWNLOADS],
      availableOffline: false
    }
  },
  {
    path: '/previous-orders',
    name: 'previousOrders',
    component: () => import('@/views/PreviousOrders.vue'),
    meta: {
      middleware: 'auth',
      title: 'previous_orders',
      permissions: [SHOW_PAST_ORDERS],
      netsuiteIdRequired: true,
      availableOffline: false
    }
  },
  {
    path: '/previous-orders/:id',
    name: 'previousOrderSummary',
    component: () => import('@/views/PreviousOrderSummary.vue'),
    meta: {
      middleware: 'auth',
      title: 'previous_orders',
      permissions: [SHOW_PAST_ORDERS],
      netsuiteIdRequired: true,
      availableOffline: false
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'termsConditions',
    component: () => import('@/views/TermsConditionsView.vue'),
    meta: {
      middleware: 'auth',
      title: 'terms'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      middleware: 'guest',
      title: 'login'
    }
  },
  {
    path: '/password/reset',
    name: 'forgotPassword',
    component: () => import('@/views/ForgotPasswordView.vue'),
    meta: {
      middleware: 'guest',
      title: 'forgot_password',
      availableOffline: false
    }
  },
  {
    path: '/password/reset/:token',
    name: 'resetPassword',
    component: () => import('@/views/ResetPasswordView.vue'),
    meta: {
      middleware: 'guest',
      title: 'forgot_password',
      availableOffline: false
    }
  },
  {
    path: '/password/change',
    name: 'changePassword',
    component: () => import('@/views/ChangePasswordView.vue'),
    meta: {
      middleware: 'auth',
      title: 'change_password',
      availableOffline: false
    }
  },
  {
    path: '/uscan',
    name: 'uscan',
    component: () => import('@/views/UscanView.vue'),
    meta: {
      middleware: 'auth',
      title: 'uscan',
      availableOffline: false
    }
  },
  {
    path: '/ucare',
    name: 'ucare',
    component: () => import('@/views/ucare/UcareView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ucare',
      availableOffline: false
    }
  },
  {
    path: '/ucare/register-stairlift',
    name: 'ucareRegisterStairlift',
    component: () => import('@/views/ucare/RegisterStairliftView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ucare_register_stairlift',
      permissions: [CLM_REGISTER_STAIRLIFT],
      netsuiteIdRequired: true,
      availableOffline: false,
      impersonatingBypass: ['platinum_admin', 'platinum_office', 'platinum_super_admin', 'platinum_engineer']
    }
  },
  {
    path: '/ucare/registered-stairlifts',
    name: 'ucareRegisteredStairlifts',
    component: () => import('@/views/ucare/RegisteredStairliftsView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ucare_registered_stairlifts',
      permissions: [CLM_VIEW_REGISTERED_STAIRLIFT_LIST],
      availableOffline: false
    }
  },
  {
    path: '/ucare/registered-stairlifts/:id',
    name: 'ucareRegisteredStairliftItem',
    component: () => import('@/views/ucare/registered-stairlift/StairliftItemView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ucare_registered_stairlift',
      permissions: [CLM_VIEW_STAIRLIFT_DETAILS],
      availableOffline: false
    }
  },
  {
    path: '/ucare/registered-stairlifts/:iccid/history/:id',
    name: 'ucareRegisteredStairliftItemHistory',
    component: () => import('@/views/ucare/registered-stairlift/history/HistoryView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ucare_registered_stairlift_history',
      permissions: [CLM_DETAILED_HISTORY_VIEW],
      availableOffline: false
    }
  },
  {
    path: '/ucare/search',
    name: 'ucareSearch',
    component: () => import('@/views/ucare/SearchView.vue'),
    meta: {
      middleware: 'auth',
      title: 'ucare_search',
      permissions: [CLM_STAIRLIFT_SEARCH],
      availableOffline: false
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'ErrorAll',
    component: () => import('@/views/404NotFoundView.vue'),
    meta: {
      middleware: 'guest',
      title: 'not_found'
    }
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return false
    }
    return { top: 0 }
  }
})

function setEnIfUcare(to: any) {
  if (to.path.includes('ucare')) {
    useAuthStore().userLocale = 'en'
    localStorage.setItem('selectedLanguage', 'en')
  }
}

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  const middleware = to.meta.middleware ?? null
  const routePermissions = to.meta.permissions ?? null
  const userPermissions = authStore.user ? authStore.user.permissions : []
  const userRole = authStore.user ? authStore.user?.role : ''
  const netsuiteIdRequired = to.meta.netsuiteIdRequired ?? false
  const impersonatingBypass = to.meta.impersonatingBypass ?? []

  // Set the document title
  const title = to.meta.title as string

  setEnIfUcare(to)

  switch (authStore.userLocale) {
    case 'de':
      document.title = `${globalContentDe.page_names[title]} - ${globalContentDe.app_name}`
      break
    case 'es':
      document.title = `${globalContentEs.page_names[title]} - ${globalContentEs.app_name}`
      break
    case 'fr':
      document.title = `${globalContentFr.page_names[title]} - ${globalContentFr.app_name}`
      break
    case 'it':
      document.title = `${globalContentIt.page_names[title]} - ${globalContentIt.app_name}`
      break
    default:
      document.title = `${globalContentEn.page_names[title]} - ${globalContentEn.app_name}`
  }

  if (import.meta.env.MODE === 'development' && import.meta.env.VITE_BYPASS_AUTH === 'true') {
    next()
    return
  }

  if (to.meta.availableOffline === false && !useMainStore().navigatorOnline) {
    next({ name: 'home' })
  }

  if (to.name === 'login' && authStore.authenticated) {
    // User is authenticated and trying to navigate to the 'login' route
    next({ name: 'home' }) // redirect to the home page
  } else if (authStore.authenticated) {
    // User is authenticated
    if (netsuiteIdRequired && !authStore.hasCompanyName) {
      // The route requires a netsuite_id but the user does not have one
      next({ name: 'ErrorAll', params: { catchAll: 'not-found' } })
    } else if (!routePermissions || (Array.isArray(routePermissions) && userPermissions.some((p: UserPermissions) => routePermissions.includes(p)))) {
      // No permissions are required for this route or user has required permissions and a netsuite_id
      next()
    } else if (authStore.isImpersonating && Array.isArray(impersonatingBypass) && impersonatingBypass.includes(userRole)) {
      // User is impersonating and has bypass permissions
      next()
    } else {
      // User does not have required permissions
      next({ name: 'ErrorAll', params: { catchAll: 'not-found' } })
    }
  } else if (middleware === 'auth') {
    // The route requires authentication
    if (authStore.user) {
      next()
    } else {
      // User is not authenticated
      if (from.name !== 'login') {
        next({ name: 'login' })
      } else {
        next()
      }
    }
  } else {
    // The route does not require authentication
    next()
  }
})

export default router
